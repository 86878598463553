// @file
// Styles for the footer menu.

@use '../../../00-config' as *;

.menu--footer {
  list-style-type: none;
  padding: 0;

  .menu__item {
    margin: 0 0 rem(gesso-spacing(2));
    padding-left: 0;
  }

  .menu__link {
    display: block;
    font-family: gesso-font-family(heading);
    font-size: font-size(heading, md);
    font-weight: gesso-font-weight(bold);
    line-height: gesso-line-height(2);
    outline-offset: rem(gesso-spacing('05'));
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
