// @file
// Styles for a pull quote.

@use '../../00-config' as *;
@use '../../02-base' as *;

.pull-quote {
  @extend %pull-quote;

  @include breakpoint(gesso-breakpoint(tablet)) {
    &.u-align-left,
    &.u-align-right {
      margin-top: 0;
      max-width: calc(50% - #{rem(gesso-get-map(gutter-width))});
    }
  }
}

.pull-quote__cite {
  @extend %pull-quote__cite;
}
