// @file
// Styles for an External Image Link.

@use '../../../00-config' as *;

%external-link--image-after {
  background-color: rgba(255, 255, 255, 0.825);
  background-position: center;
  background-size: units('105');
  margin: 0 !important;
  padding: units('105');
  position: absolute;
  right: units('05');
  top: units('05');
}

.external-link--image {
  display: inline-block;
  position: relative;

  &::after {
    @extend %external-link--image-after;
  }

  .icon--exit {
    margin: 0 !important;
    position: absolute;
    right: units('05');
    top: units('05');
  }
}
