// @file
// Styles for a Multipurpose Box.

@use '../../../00-config' as *;
@use '../box' as *;

.box--multipurpose {
  @extend %box--with-borders;

  .usa-dark-background & {
    > .box__title,
    > .box__footer {
      background-color: gesso-grayscale(gray-4);
      border-color: gesso-grayscale(gray-4);
    }

    > .box__content {
      border-color: gesso-grayscale(gray-4);
    }
  }
}
