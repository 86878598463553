// @file
// Styles for a view.

@use '../../00-config' as *;

// .view {}

.view__filters {
  margin-bottom: rem(gesso-spacing(2));
}
