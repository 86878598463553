// @file
// Styles for an icon.

@use '../../00-config' as *;

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  overflow: visible !important;
  position: relative;
  top: -2px;
  transition: none;
  vertical-align: middle;
  width: 1em;

  // Fixes delayed transitions in Chrome.
  * {
    transition: none;
  }

  &.is-spaced-before {
    margin-left: 0.25em;
  }

  &.is-spaced-after {
    margin-right: 0.25em;
  }
}

.icon--exit,
.icon--pager,
.icon--social {
  top: 0;
}

.icon--facet-limit {
  margin-right: rem(gesso-spacing(1));
}

.icon--filter {
  top: -1px;
}

.icon--rss {
  color: #ee802f;
}
