@use '../../../00-config' as *;

.diff-header {
  background: gesso-grayscale(gray-1);
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  padding: 2rem;

  .diff-revision {
    margin-top: 0;
  }
}

.diff-controls {
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  &__item + .diff-controls__item {
    margin-top: 0;
  }
}

.diff-responsive-table-wrapper {
  margin-bottom: 2rem;
}
