// @file
// Inline element styles.

@use '../../../00-config' as *;

a {
  background-color: transparent;
  color: gesso-color(text, link);
  -webkit-text-decoration-skip: objects;

  &:visited {
    color: gesso-color(text, link-visited);
  }

  &:hover,
  &:focus {
    color: gesso-color(text, link-hover);
  }

  &:active {
    color: gesso-color(text, link-active);
  }

  @media print {
    &,
    &:visited {
      text-decoration: none;
    }

    &[href]::after {
      content: ' <' attr(href) '>';
      font-family: font-family(body);
      font-size: 10pt;
      font-weight: normal;
      text-transform: lowercase;
    }

    &[href^='/']::after {
      content: ' <https://epa.gov' attr(href) '>';
    }

    &[href^='javascript:']::after,
    &[href^='mailto:']::after,
    &[href^='tel:']::after,
    &[href^='#']::after,
    &[href*='?']::after {
      content: '';
    }
  }
}

abbr {
  &[title] {
    border-bottom: 1px dotted;
    text-decoration: none;

    @include breakpoint(max-width gesso-breakpoint(tablet)) {
      border-bottom: 0;

      &::after {
        content: ' (' attr(title) ')';
      }
    }
  }

  &:hover {
    cursor: help;
  }

  @media print {
    &[title] {
      border-bottom: 0;

      &::after {
        content: ' (' attr(title) ')';
      }
    }
  }
}

// b {}

cite {
  @include display-text-style(cite);
}

// code {}

// del {}

dfn {
  font-style: normal;
}

// em {}

// i {}

ins {
  text-decoration: none;
}

// kbd {}

mark {
  background: #ff0;
  color: #000;
}

// q {}

// s {}

// samp {}

small {
  font-size: 80%;
}

// strong {}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

// time {}

// u {}

var {
  font-style: normal;
}
