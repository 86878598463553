.usa-modal {
  .usa-modal__close {
    align-items: center;
    align-self: flex-end;
    background-color: transparent;
    color: #757575;
    display: flex;
    flex-shrink: 0;
    font-size: 0.93rem;
    margin: -2rem 0 0 auto;
    padding: 0.25rem;
    width: auto;
  }
}
