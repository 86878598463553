// @file
// Link mixin

@mixin link($link, $hover: $link, $active: $hover, $visit: $link) {
  color: $link;

  &:visited {
    color: $visit;
  }

  &:hover,
  &:focus {
    color: $hover;
  }

  &:active {
    color: $active;
  }
}
