// @file
// Styles for the Before/After Swipe.

@use 'sass:math';
@use '../../00-config' as *;

$before-after-swipe-border-size: rem(2px);
$before-after-swipe-button-size: rem(44px);

.before-after-swipe {
  --split-point: 50%;
  position: relative;

  &.is-enabled {
    display: inline-grid;

    .before-after-swipe__image,
    .before-after-swipe__slider {
      grid-area: 1/1;
    }

    .before-after-swipe__image--before {
      border-bottom: 0;
      clip-path: inset(
        0 calc(100% - var(--split-point) + #{$before-after-swipe-border-size}) 0
          0
      );
      z-index: 1;
    }

    .before-after-swipe__image--after {
      clip-path: inset(
        0 0 0 calc(var(--split-point) + #{$before-after-swipe-border-size})
      );
      z-index: 2;
    }

    .before-after-swipe__slider,
    .before-after-swipe__slider-button {
      display: flex;
    }
  }
}

.before-after-swipe__image--before {
  border-bottom: $before-after-swipe-border-size solid #fff;
}

@mixin before-after-swipe__slider-thumb {
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  height: $before-after-swipe-button-size;
  outline: 0;
  width: $before-after-swipe-button-size;
}

.before-after-swipe__slider {
  align-items: center;
  background: transparent;
  display: none;
  justify-content: center;
  margin: 0;
  opacity: 0;
  outline: none;
  transition: all 0.2s;
  z-index: 3;

  &:hover {
    background: rgba(#f2f2f2, 0.1);
  }

  &::-moz-range-thumb {
    @include before-after-swipe__slider-thumb();
  }

  &::-ms-thumb {
    @include before-after-swipe__slider-thumb();
  }

  &::-webkit-slider-thumb {
    @include before-after-swipe__slider-thumb();
  }

  &::-moz-range-track {
    min-height: $before-after-swipe-button-size;
  }

  &::-webkit-slider-runnable-track {
    min-height: $before-after-swipe-button-size;
  }
}

.before-after-swipe__slider-button {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: gesso-color(text, primary);
  display: none;
  font-size: rem(28px);
  height: $before-after-swipe-button-size;
  justify-content: center;
  left: 50%;
  margin: #{math.div($before-after-swipe-button-size, -2)} 0 0 #{math.div(
      $before-after-swipe-button-size,
      -2
    )};
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: $before-after-swipe-button-size;
  z-index: 4;
}

.before-after-swipe__slider-button-icon {
  transform: rotate(90deg);
}
