// @file
// Styles for a Header Link.

@use '../../00-config/00-functions' as *;

.header-link {
  line-height: gesso-line-height(2);
  text-decoration: none;
  text-transform: uppercase;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }

  @media print {
    display: none;
  }
}
