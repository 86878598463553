// @file
// Styles for a Web Area Title.

@use '../../00-config' as *;
@use '../../02-base' as *;

.web-area-title {
  @extend %h3;
  margin: 0;
}
