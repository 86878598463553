// @file
// Styles for a Blog Box.

@use '../../../00-config' as *;
@use '../box' as *;

.box--blog {
  @extend %box--with-borders;

  > .box__title,
  > .box__footer {
    background-color: gesso-brand(green, light-1);
    border-color: gesso-brand(green, light-1);
    color: gesso-grayscale(gray-7);
  }

  > .box__title > .box__title-link {
    color: gesso-grayscale(gray-7);

    &::after {
      @include svg-background-important(external-link--gray-7);
    }
  }

  > .box__image,
  > .box__image-link > .box__image {
    border-color: gesso-brand(green, light-1);
  }

  > .box__content {
    border-color: gesso-brand(green, light-1);
  }

  > .box__footer > .box__footer-link {
    color: gesso-color(text, link);

    &:visited {
      color: gesso-color(text, link-visited);
    }

    &:hover,
    &:focus {
      color: gesso-color(text, link-hover);
    }

    &:active {
      color: gesso-color(text, link-active);
    }
  }
}
