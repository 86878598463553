// @file
// Styles for an Inverse External Link.

@use '../../../00-config' as *;

%external-link--inverse {
  &:link,
  &:visited {
    color: gesso-grayscale(gray-2);

    &::after {
      // stylelint-disable-next-line selector-no-qualifying-type
      html.no-js & {
        @include svg-background(external-link--gray-2);
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: gesso-grayscale(white);

    &::after {
      // stylelint-disable-next-line selector-no-qualifying-type
      html.no-js & {
        @include svg-background(external-link--white);
      }
    }
  }
}

.external-link--inverse {
  @extend %external-link--inverse;
}

// stylelint-disable-next-line selector-no-qualifying-type
html.no-js .usa-dark-background a#{$external-urls} {
  @extend %external-link--inverse;
}
