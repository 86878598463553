// @file
// Styles for Block.

@use '../../00-config/00-functions' as *;

.block {
  margin-bottom: rem(gesso-spacing(3));
}

// Disable margin for layout builder blocks.
.block--provider-layout-builder {
  margin-bottom: 0;
}
