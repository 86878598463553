// @file
// Paragraph styles.

@use '../../../00-config' as *;

p {
  @include typeset-p();

  @media print {
    orphans: 3;
    widows: 3;
  }

  // Hide empty paragraphs except in WYSIWYG editor.
  @if not $wysiwyg {
    &:empty {
      margin: 0 !important;
    }
  }
}
