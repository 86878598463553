@use '../../00-config' as *;

.usa-button {
  &:focus {
    color: gesso-grayscale(white);
  }

  .usa-dark-background
    &:not(.usa-button--outline, .usa-button--unstyled):disabled {
    background-color: gesso-grayscale(gray-4);
    color: gesso-grayscale(gray-3);

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      background-color: gesso-grayscale(gray-4);
      color: gesso-grayscale(gray-3);
    }
  }
}
