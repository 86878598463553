// @file
// Styles for a Definition.

@use '../../00-config' as *;
@use '../../02-base' as *;
@use 'usa-button' as *;

.definition {
  cursor: help;

  &:focus-within {
    .definition__tooltip {
      display: block;
    }
  }
}

.definition__trigger {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .usa-button--unstyled;
  border-bottom: 1px dotted;
  position: relative;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus + .definition__tooltip {
    display: block;
  }
}

.definition__tooltip {
  background: gesso-brand(blue, dark-1);
  color: gesso-grayscale(white);
  display: none;
  font-size: font-size(body, xs);
  margin-top: rem(-3px);
  max-width: rem(960px);
  padding: gesso-spacing(2);
  position: absolute;
  word-break: break-word;
  z-index: 1;

  @include breakpoint(gesso-breakpoint(tablet)) {
    min-width: rem(320px);

    .l-sidebar__sidebar & {
      min-width: rem(150px);
    }
  }

  a:not(.usa-button, .button) {
    @extend %dark-bg-link;
  }
}

.definition__term {
  color: gesso-brand(aqua, base);
  display: block;
  font-family: font-family(heading);
  font-size: font-size(heading, md);
  font-weight: gesso-bold(bold);
  margin-bottom: gesso-spacing(1);
}
