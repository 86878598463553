// @file
// Alignment utility classes

@use '../00-config' as *;

// Align items
.u-align-left {
  @include breakpoint(gesso-breakpoint(tablet)) {
    float: left;
    margin-right: rem(gesso-get-map(gutter-width));
  }

  .l-sidebar__sidebar &,
  .l-grid &,
  .l-resource-directory & {
    float: none;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.u-align-right {
  @include breakpoint(gesso-breakpoint(tablet)) {
    float: right;
    margin-left: rem(gesso-get-map(gutter-width));
  }

  body:not(.has-wide-template) .l-sidebar__sidebar &,
  .l-grid &,
  .l-resource-directory & {
    float: none;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

// Clear floats
.u-clear-both,
.clear-both {
  clear: both;
}

.u-clear-left,
.clear-left {
  clear: left;
}

.u-clear-right,
.clear-right {
  clear: right;
}
