// @file
// Styles for an unstyled table.

@use '../../../00-config' as *;

.usa-table--unstyled {
  tbody,
  thead {
    th {
      background-color: transparent;
    }
  }

  td,
  th {
    background-color: transparent;
    border: 0;
  }

  .usa-dark-background & {
    color: gesso-grayscale(white);

    td,
    th {
      color: gesso-grayscale(white);
    }
  }
}
