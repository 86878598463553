// @file
// Styles for a Warning.

@use '../../00-config' as *;

// stylelint-disable-next-line selector-no-qualifying-type
span.warning {
  color: color($theme-color-error);
  font-weight: gesso-font-weight(bold);
}
