// @file
// Styles for File.

@use '../../00-config' as *;

.file {
  &.is-private {
    background-color: rgba(color($theme-color-warning), 0.5);
    padding-left: 0.125em;
  }
}

.file__private-icon {
  background-color: color($theme-color-warning);
  color: color($theme-color-base-darkest);
  margin-left: 0.125em;
  padding: 0 0.125em;
}
