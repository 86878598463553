// @file
// Styles for a Slideshow Hero.

@use '../../../00-config' as *;

.usa-hero--slideshow {
  min-height: rem(350px);
  padding-bottom: gesso-spacing(7);

  @include breakpoint(max-width gesso-breakpoint(tablet)) {
    background-image: none !important;
    min-height: 0;
    padding-top: 0;
  }

  .usa-hero__callout {
    > :last-child {
      margin-bottom: 0;
    }
  }

  // Use CSS grid to set equal height slides.
  @supports (display: grid) {
    flex-grow: 1;
    height: 100%;

    @include breakpoint(max-width gesso-breakpoint(tablet)) {
      .grid-container,
      .usa-hero__callout {
        height: 100%;
      }
    }
  }
}
