// @file
// Styles for a 6 Column Grid Layout.

@use '../../../00-config' as *;

.l-grid--6-col {
  @include grid-gap(5);

  @include breakpoint(333px 500px) {
    > * {
      @include grid-col(6);
    }
  }

  @include breakpoint(500px 1000px) {
    > * {
      @include grid-col(4);
    }
  }

  @include breakpoint(1000px) {
    > * {
      @include grid-col(2);
    }
  }
}
