// @file
// SVG background image

@use '../00-functions' as *;

@mixin svg-background($image-name, $image-location: '../images/') {
  $url: $image-location + $image-name + '.svg';
  background-image: url($url);
}

@mixin svg-background-important($image-name, $image-location: '../images/') {
  $url: $image-location + $image-name + '.svg';
  background-image: url($url) !important;
}

@mixin svgz-background($image-name, $image-location: '../images/') {
  $url: $image-location + $image-name + '.svgz';
  background-image: url($url);
}

@mixin svg-background-inline($image-name, $image-location: '../images/') {
  background-image: inline($image-location + $image-name + '.svg');
}

@mixin svgz-background-inline($image-name, $image-location: '../images/') {
  background-image: inline($image-location + $image-name + '.svgz');
}
