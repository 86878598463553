// @file
// Dark Background Links

@use '../../00-config' as *;

// Dark gray background with white links.
%dark-bg-link {
  &:link,
  &:visited {
    color: gesso-grayscale(gray-2);

    &::after {
      // stylelint-disable-next-line selector-no-qualifying-type
      html.no-js & {
        @include svg-background-important(external-link--gray-2);
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: gesso-grayscale(white);

    &::after {
      // stylelint-disable-next-line selector-no-qualifying-type
      html.no-js & {
        @include svg-background-important(external-link--white);
      }
    }
  }
}
