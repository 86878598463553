// @file
// Print utility classes

@use '../00-config' as *;

.u-hide-for-print {
  @media print {
    display: none;
  }
}
