// @file
// Styles for a Base Button Group.

@use '../../../00-config' as *;
@use 'usa-button' as *;

.button-group--base {
  .button-group__item {
    border-color: color('base-dark');
  }

  .button-group__link {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .usa-button--base;

    &:focus {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .usa-button--base, :hover;
    }

    &.is-active {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .usa-button--base, :active;
    }
  }
}
