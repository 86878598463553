// @file
// Styles for Button.

@use '../../00-config' as *;
@use 'usa-button' as *;

.button {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .usa-button;
  margin-bottom: gesso-spacing(1);
  margin-right: 0;

  &:focus {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .usa-button, :hover;
    z-index: 1;
  }

  @include breakpoint(gesso-breakpoint(mobile-lg)) {
    margin-right: gesso-spacing(1);
  }
}
