// @file
// Styles for the Page Layout.

@use '../../00-config' as *;

.l-page {
  margin-bottom: rem(gesso-spacing(4));
  padding-top: rem(gesso-spacing(2));

  &.has-footer {
    margin-bottom: 0;
  }
}

.l-page__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: rem(gesso-spacing(2));

  @include breakpoint(gesso-breakpoint(tablet)) {
    flex-wrap: nowrap;
  }
}

.l-page__header-first {
  margin-bottom: rem(gesso-spacing(2));
  margin-right: rem(gesso-get-map(gutter-width));
}

.l-page__header-last {
  flex-shrink: 0;
}

.l-page__footer {
  background-color: gesso-grayscale('gray-1');
  margin-top: rem(gesso-spacing(4));
  padding-bottom: rem(gesso-site-margins(mobile));
  padding-top: rem(gesso-site-margins(mobile));

  :last-child {
    margin-bottom: 0;
  }

  @include at-media($theme-site-margins-breakpoint) {
    padding-bottom: rem(gesso-site-margins(desktop));
    padding-top: rem(gesso-site-margins(desktop));

    .l-constrain,
    .block {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .l-constrain {
      flex-wrap: wrap;
    }
  }

  .page-has-sidebar &,
  .page-no-sidebar & {
    background-color: gesso-grayscale(white);
    margin-top: 0;
    padding-bottom: rem(gesso-spacing(6));

    .l-constrain,
    .block {
      flex-wrap: wrap;
    }
  }

  .page-no-sidebar & {
    .l-constrain {
      display: flex;
      padding-top: rem(gesso-spacing(3));
      position: relative;

      &::before {
        background-color: gesso-grayscale(gray-3);
        content: '';
        height: 1px;
        position: absolute;
        top: 0;
        width: measure(5);
      }
    }
  }
}

.l-page__footer-contact {
  .page-has-sidebar & {
    display: none;
  }

  .page-no-sidebar & {
    width: 100%;
  }
}

.l-page__footer-last-updated {
  font-size: font-size(body, 3xs);
  line-height: gesso-line-height(2);
  margin-top: 1rem;

  @include at-media($theme-site-margins-breakpoint) {
    margin-left: 1rem;
    margin-top: 0;
  }

  .page-has-sidebar & {
    @include breakpoint(gesso-breakpoint(sidebar)) {
      margin-left: calc(25% + 2.5rem);
    }
  }

  .page-no-sidebar & {
    margin-left: 0;
    margin-top: rem(gesso-spacing(1));
    width: 100%;
  }
}

.l-page__footer-contact-last {
  font-size: font-size(body, 3xs);
  line-height: gesso-line-height(2);

  @include at-media($theme-site-margins-breakpoint) {
    text-align: right;
    width: 100%;
  }
}
