// @file
// Styles for accessible skiplinks.

@use '../../00-config' as *;

$skiplinks-font-family-token: ui;

.skiplinks {
  display: flex;
  font-family: gesso-font-family($skiplinks-font-family-token);
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.skiplinks__link,
.skiplinks__link:visited,
.skiplinks__link:focus {
  background-color: gesso-color(ui, generic, background-dark);
  border: 2px solid gesso-color(ui, generic, border-light);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-shadow: gesso-box-shadow(2);
  color: gesso-color(text, on-dark);
  display: inline-block;
  font-weight: bold;
  line-height: line-height($skiplinks-font-family-token, 2);
  margin: 0;
  outline: 0;
  padding: rem(gesso-spacing(1));
  text-align: center;
  text-decoration: none;
}

// Remove :focus styles for skiplink targets.
// stylelint-disable-next-line selector-max-id
#main:focus {
  outline: 0;
}
