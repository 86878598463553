// @file
// Styles for the Footer.

@use 'sass:math';
@use '../../00-config' as *;
@use '../../02-base' as *;

$bp-footer-columns: 700px;

.footer {
  background-color: gesso-grayscale(gray-6);
  color: gesso-grayscale(white);
  min-height: rem(100px) + rem(gesso-spacing(10));
  padding: rem(gesso-spacing(5)) 0;

  a:not(.usa-button, .button) {
    @extend %dark-bg-link;
  }

  @media print {
    display: none;
  }
}

.footer__pre-footer {
  border-bottom: 1px solid gesso-grayscale(white);
  margin-bottom: rem(gesso-spacing(4));
  padding-bottom: rem(gesso-spacing(2));
}

.footer__epa-seal {
  float: left;
  margin: 0 rem(gesso-spacing(5)) rem(gesso-spacing(5)) 0;

  @include breakpoint($bp-footer-columns) {
    margin-right: math.div(rem(gesso-spacing(5)), rem($bp-footer-columns)) *
      100%;
  }
}

.footer__content {
  display: inline-block;

  @include breakpoint($bp-footer-columns) {
    display: flex;
    justify-content: space-between;
  }
}

.footer__column {
  margin-bottom: rem(46.8px);

  @include breakpoint($bp-footer-columns) {
    margin-bottom: 0;
    width: 30%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
