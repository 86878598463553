@use '../../00-config' as *;

.usa-graphic-list {
  .usa-media-block__img {
    border-radius: 50%;
    max-width: rem(125px);

    + .icon--exit {
      display: none;
    }
  }
}
