// @file
// Styles for a sortable table.

@use '../../../00-config' as *;

.usa-table--sortable {
  // stylelint-disable-next-line
  html.js & thead th {
    @include svg-background(sort);
    background-position: right 0.5rem top 50%;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    cursor: pointer;
    padding-right: 2.5rem;

    // stylelint-disable-next-line selector-max-compound-selectors
    &:hover {
      @include svg-background(sort-black);
    }

    // stylelint-disable-next-line selector-max-compound-selectors, selector-no-qualifying-type
    &[aria-sort='ascending'] {
      @include svg-background(sort-up);

      // stylelint-disable-next-line selector-max-compound-selectors
      &:hover {
        @include svg-background(sort-up-black);
      }
    }

    // stylelint-disable-next-line selector-max-compound-selectors, selector-no-qualifying-type
    &[aria-sort='descending'] {
      @include svg-background(sort-down);

      // stylelint-disable-next-line selector-max-compound-selectors
      &:hover {
        @include svg-background(sort-down-black);
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  html.js .usa-dark-background & {
    // stylelint-disable-next-line selector-max-compound-selectors
    thead th {
      @include svg-background(sort-white);

      // stylelint-disable-next-line selector-max-compound-selectors
      &:hover,
      &:focus,
      &:focus-within {
        @include svg-background(sort-light-gray);
      }
    }
  }
}
