// @file
// Styles for the mobile menu buttons.

@use '../../00-config' as *;

.mobile-menu-button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  font-family: gesso-font-family(ui);
  font-size: font-size(ui, sm);
  font-weight: gesso-font-weight(bold);
  height: 55px;
  line-height: 55px;
  margin: 0;
  padding: 0 gesso-spacing(2);
  text-decoration: none;

  &:focus {
    @include focus-outline();
  }
}

.mobile-menu-button__icon {
  @include image-replace(22px, 22px);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  display: inline-block;
  margin-top: gesso-spacing(2);
}

.mobile-menu-button--close {
  .mobile-menu-button__icon {
    @include svg-background-inline(mobile-close);
  }
}

.mobile-menu-button--menu {
  .mobile-menu-button__icon {
    @include svg-background-inline(mobile-menu);
    background-size: 33px 22px;
    width: 33px;
  }
}
