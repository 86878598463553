@use '../../00-config' as *;
@use '../../02-base/03-extendables' as *;

// Additional styles needed to work within dark backgrounds.
.usa-dark-background {
  .usa-card {
    color: gesso-color(text, primary);

    a:not(.usa-button, .button) {
      @extend %light-bg-link;
    }

    p,
    span {
      color: gesso-color(text, primary);
    }
  }

  .usa-card__heading {
    color: gesso-color(text, primary);
  }
}

.usa-card__footer {
  .usa-button {
    max-width: 100%;
  }
}
