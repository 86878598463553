// @file
// Preformatted text styles.

@use '../../../00-config' as *;

pre {
  margin: 0 0 rem(gesso-spacing(2));
  overflow: auto;

  // Add top margin when preceded by another element.
  * + & {
    margin-top: rem(gesso-spacing(2));
  }

  @media print {
    page-break-inside: avoid;
  }
}
