@use '../../00-config' as *;

.usa-site-alert {
  .usa-alert {
    margin-bottom: 0;
    overflow: visible;

    .usa-alert__body {
      max-width: 100%;
    }
  }

  .usa-alert__text {
    > :last-child {
      margin-bottom: 0;
    }
  }

  .usa-alert__body {
    display: flex;
    justify-content: space-between;
  }
}

.usa-site-alert--emergency {
  .usa-alert__text a {
    color: color('gray-10');
  }
}

// display alerts on the alerts page even when closed out elsewhere.
// stylelint-disable-next-line selector-class-pattern
.view--public-alerts.view--display-page_1 {
  .usa-site-alert {
    margin-bottom: 0.5em;

    &.u-hidden {
      display: block !important;
    }

    // hide close button since it doesn't do anything here
    .usa-alert__close {
      display: none;
    }
  }
}
