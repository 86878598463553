// @file
// Styles for an Image Gallery.

@use '../../00-config' as *;
@use '../../02-base' as *;
@use '../../tiny-slider';

$image-gallery-button-size: 44px;

.image-gallery {
  clear: both;
  margin-bottom: rem(units(4));
  margin-left: auto;
  margin-right: auto;
  max-width: rem(640px + $image-gallery-button-size * 2);
  padding: 0 rem($image-gallery-button-size);
  position: relative;

  * + & {
    margin-top: rem(gesso-spacing(4));
  }

  // stylelint-disable
  &.gallery_small,
  &.gallery_small_tall {
    max-width: rem(320px + $image-gallery-button-size * 2);
  }

  &.gallery_large,
  &.gallery_large_tall {
    max-width: rem(960px + $image-gallery-button-size * 2);
  }
  // stylelint-enable
}

.image-gallery__title {
  @extend %h3;
  position: relative;
  z-index: 1;
}

.image-gallery__list {
  @include list-clean();
}

.image-gallery__nav {
  line-height: 0;
  padding-bottom: rem(gesso-spacing('05'));
  position: relative;
  z-index: 1;
}

.image-gallery__nav-button {
  background: gesso-brand(blue, light-1);
  border: 0;
  border-radius: 50%;
  height: rem(gesso-spacing(1));
  margin-right: rem(gesso-spacing(1));
  padding: 0;
  width: rem(gesso-spacing(1));

  &.tns-nav-active {
    background-color: gesso-brand(blue, light);
  }
}

.image-gallery__controls {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &:focus {
    outline-offset: 2px;
  }

  &:focus-within {
    @include focus-outline();
  }
}

%image-gallery__button {
  background: transparent;
  border: 0;
  bottom: 0;
  box-shadow: 0;
  color: gesso-brand(blue, base);
  font-size: rem(gesso-spacing(4));
  padding: 0;
  position: absolute;
  top: 0;
  width: rem($image-gallery-button-size);

  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: gesso-brand(blue, dark);
    outline: 0 !important;
  }
}

.image-gallery__prev {
  @extend %image-gallery__button;
  left: 0;
}

.image-gallery__next {
  @extend %image-gallery__button;
  right: 0;
}

.image-gallery__prev,
.image-gallery__next {
  .usa-dark-background &,
  .usa-section--dark & {
    background: transparent;

    svg {
      fill: gesso-grayscale(white);
    }
  }
}
