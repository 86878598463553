@use '../../00-config' as *;
@use '../../02-base' as *;

.usa-hero {
  background-image: none;

  @media print {
    display: none;
  }
}

.usa-hero__callout.usa-dark-background {
  background-color: color('primary-darker');
  max-width: 25rem;
}
