// @file
// Styles for a Facet List.

@use '../../00-config' as *;

.facet-list {
  @include list-clean();

  & & {
    padding: rem(gesso-spacing(2)) 0 0 rem(gesso-spacing(3));
  }
}

.facet-list__item {
  & + & {
    margin-top: rem(gesso-spacing(2));
  }
}
