// @file
// Styles for the social menu.

@use '../../../00-config' as *;

$social-menu-icon-size: rem(40px);
$social-menu-gap: rem(gesso-spacing(1));

.menu--social {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;

  @include breakpoint(700px) {
    justify-content: center;
    max-width: 3 * ($social-menu-icon-size + $social-menu-gap);
  }

  @include breakpoint(1040px) {
    justify-content: flex-start;
    max-width: 100%;
  }

  .menu__item {
    margin: 0 $social-menu-gap $social-menu-gap 0;
    padding-left: 0;
  }

  .menu__link {
    display: block;
    font-size: $social-menu-icon-size;
    line-height: 0.5;
    text-decoration: none;

    // Hide external link icon/tag.
    &::after,
    .icon--exit {
      display: none !important;
    }
  }
}
