// @file
// Styles for a 3 Column Grid Layout.

@use '../../../00-config' as *;

.l-grid--3-col {
  @include grid-gap(5);

  @include breakpoint(760px) {
    > * {
      @include grid-col(4);
    }
  }
}
