// @file
// Styles for a tag list.

@use '../../00-config' as *;

.tag-list {
  @include list-clean();
}

.tag-list__item {
  display: inline-block;
  margin: 0 rem(gesso-spacing(1)) rem(gesso-spacing(1)) 0;
}
