// @file
// Styles for RSS feed.

@use '../../00-config/00-functions' as *;

.rss-feed {
  display: inline-block;
  margin-top: rem(gesso-spacing(4));

  @media print {
    display: none;
  }
}
