// @file
// Styles for EPA Nav.

@use '../../../00-config' as *;

.usa-nav--epa {
  width: 18rem;

  @include at-media($theme-header-min-width) {
    background: gesso-brand(blue, dark);
    float: none;
    width: auto;
  }

  .usa-header--extended & {
    border-top: 0;
  }

  .usa-nav__inner {
    position: relative;

    @include at-media($theme-header-min-width) {
      @include layout-constrain();
    }
  }

  .usa-nav__close {
    color: gesso-grayscale(gray-7);
    font-size: rem(gesso-spacing(4));
    margin: rem(gesso-spacing(1) * -1);
    margin-bottom: rem(gesso-spacing(1));
  }

  .usa-nav__menu {
    margin: rem(gesso-spacing(3)) rem(gesso-spacing(2) * -1);

    @include at-media($theme-header-min-width) {
      margin: 0;
    }
  }
}
