// @file
// Styles for a roomy list.

@use '../../../00-config' as *;

.list--roomy {
  ol,
  ul {
    margin-top: rem(gesso-spacing(2));
  }

  li {
    margin-bottom: rem(gesso-spacing(2));
  }
}
