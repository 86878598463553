// @file
// Styles for Grid Layout.

@use '../../00-config' as *;

.l-grid {
  @include grid-row();

  > * {
    @include grid-col(12);
  }
}
