// @file
// Styles for Header Layout.

@use '../../00-config' as *;

.l-header {
  background: gesso-brand(blue, base);
  position: relative;
  z-index: 2;
}

.l-header__navbar {
  @include at-media($theme-header-min-width) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.l-header__branding {
  align-items: center;
  display: flex;
  gap: rem(gesso-spacing(2));
  justify-content: space-between;
  padding: rem(gesso-spacing('05')) 0;

  @include at-media($theme-header-min-width) {
    padding: rem(gesso-spacing(205)) 0;
  }
}

%l-header__button {
  background: transparent;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: none;
  color: gesso-grayscale(gray-2);
  font-size: rem(14px);
  font-weight: normal;
  height: rem(38px);
  margin: 0;
  padding: 0 rem(12px);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:focus {
    outline-color: gesso-grayscale(white) !important;
    outline-offset: 3px !important;
    outline-width: 2px !important;
  }

  @media print {
    display: none;
  }
}

.l-header__search-button {
  @extend %l-header__button;
  font-size: rem(24px);
  padding: 0;
  width: rem(38px);

  &[aria-expanded='false'] {
    .l-header__search-button-icon-open {
      display: inline-block !important;
    }
  }

  &[aria-expanded='true'] {
    .l-header__search-button-icon-close {
      display: inline-block;
    }
  }

  @include at-media($theme-header-min-width) {
    display: none;
  }

  @media print {
    display: none;
  }
}

// stylelint-disable selector-no-qualifying-type
svg.l-header__search-button-icon-open,
svg.l-header__search-button-icon-close {
  display: none;
}

svg.l-header__search-button-icon-close {
  font-size: 1.25em;
  top: -1px;
}
// stylelint-enable

.l-header__menu-button {
  @extend %l-header__button;
}

.l-header__search {
  background: gesso-grayscale(gray-1);
  margin: 0 rem(gesso-site-margins(mobile) * -1);
  padding: rem(gesso-spacing(2)) rem(gesso-site-margins(mobile));
  transition-duration: 1ms;

  @include at-media($theme-header-min-width) {
    background: transparent;
    margin: 0;
    padding: 0;
    position: relative;
  }

  @media print {
    display: none;
  }

  [data-once='header-search'] & {
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;

    @include at-media($theme-header-min-width) {
      position: relative;
    }
  }
}

.l-header__nav {
  @media print {
    display: none;
  }
}
