@use '../../../00-config' as *;

.usa-button--outline {
  &:focus {
    color: color(blue-warm-80v);
  }

  .usa-dark-background & {
    box-shadow: inset 0 0 0 2px gesso-grayscale(gray-2);

    &:hover,
    &:focus,
    &:active {
      box-shadow: inset 0 0 0 2px gesso-grayscale(white);
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      box-shadow: inset 0 0 0 2px gesso-grayscale(gray-4);
      color: gesso-grayscale(gray-4);
    }
  }
}
