// @file
// Definition list styles.

@use '../../../00-config' as *;

dd {
  margin: 0 0 rem(gesso-spacing(2)) 3ch;

  @if $support-for-rtl {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 3ch;
    }
  }
}

dl {
  margin: 0 0 rem(gesso-spacing(2));
}

// dt {}
