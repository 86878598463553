// @file
// Styles for form fieldsets.

@use '../../00-config' as *;
@use 'usa-label' as *;

.fieldset {
  margin: 0 0 rem(gesso-spacing(3));
}

.fieldset__legend {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .usa-label;
  font-weight: gesso-font-weight(bold);
  margin-bottom: gesso-spacing('05');
  margin-top: 0;
}
