// @file
// Heading styles.

@use '../../../00-config' as *;

%hN {
  @include typeset-heading();
  clear: none;
  hyphens: none;
  text-rendering: optimizeLegibility;

  @media print {
    orphans: 3;
    page-break-after: avoid;
    widows: 3;

    &::after {
      display: inline-block;
    }
  }
}

h1,
%h1 {
  @extend %hN;
  @include display-text-style(h1);

  @include breakpoint(max-width gesso-breakpoint(tablet)) {
    font-size: font-size(heading, xl);
  }
}

h2,
.h2,
%h2 {
  @extend %hN;
  @include display-text-style(h2);

  @include breakpoint(max-width gesso-breakpoint(tablet)) {
    font-size: font-size(heading, lg);
  }
}

h3,
.h3,
%h3 {
  @extend %hN;
  @include display-text-style(h3);

  @include breakpoint(max-width gesso-breakpoint(tablet)) {
    font-size: font-size(heading, md);
  }
}

h4,
.h4,
%h4 {
  @extend %hN;
  @include display-text-style(h4);
}

h5,
.h5,
%h5 {
  @extend %hN;
  @include display-text-style(h5);
}

h6,
.h6,
%h6 {
  @extend %hN;
  @include display-text-style(h6);
}
