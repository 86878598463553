// @file
// List item styles.

@use '../../../00-config' as *;

li {
  @extend %usa-list-item;
  max-width: unset;

  @media print {
    page-break-inside: avoid;
  }
}
