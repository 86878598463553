// @file
// Body styles.

@use '../../../00-config' as *;

body {
  @include typeset();
  background-color: gesso-color(background, site);
  color: gesso-color(text, primary);
  margin: 0;
  padding: 0;
  word-wrap: break-word;

  // stylelint-disable selector-no-qualifying-type
  &.has-open-mobile-menu {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
  // stylelint-enable

  // Tweak styling in WYSIWYG editor.
  @if $wysiwyg {
    padding: 0 rem(gesso-site-margins(desktop));
  }
}
