// @file
// Overrides for USWDS typography mixins

@use '../../00-functions' as *;

@mixin typeset-heading {
  @include u-margin-y(0);
  clear: none;
  margin-bottom: rem(gesso-spacing(2));

  * + & {
    margin-top: 1em;
  }
}

@mixin typeset-p {
  line-height: line-height($theme-body-font-family, $theme-body-line-height);
  margin-bottom: rem(gesso-spacing(2));
  margin-top: 0;

  * + & {
    margin-top: rem(gesso-spacing(2));
  }
}
