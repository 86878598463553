// @file
// Styles for a Highlight.

@use '../../00-config' as *;

h2,
h3,
h4,
h5,
h6 {
  // stylelint-disable-next-line selector-no-qualifying-type
  &.highlight {
    background-color: rgba(gesso-brand(aqua, light), 0.29);
    display: flow-root;
    padding: rem(units(1)) rem(units(2));

    // stylelint-disable-next-line selector-no-qualifying-type
    .usa-dark-background & {
      color: gesso-grayscale(white);
    }
  }
}
