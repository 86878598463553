// @file
// Styles for a News Box.

@use '../../../00-config' as *;
@use '../box' as *;

.box--news {
  @extend %box--with-borders;

  > .box__title,
  > .box__footer {
    background-color: gesso-brand(blue, dark);
    border-color: gesso-brand(blue, dark);
    color: gesso-grayscale(white);
  }

  > .box__title > .box__title-link {
    color: gesso-grayscale(white);

    &::after {
      @include svg-background-important(external-link--white);
    }
  }

  > .box__image,
  > .box__image-link > .box__image {
    border-color: gesso-brand(blue, dark);
  }

  > .box__content {
    border-color: gesso-brand(blue, dark);
  }

  .usa-dark-background & {
    > .box__title,
    > .box__footer {
      background-color: gesso-brand(blue, base);
      border-color: gesso-brand(blue, base);
    }

    > .box__content {
      border-color: gesso-brand(blue, base);
    }
  }
}
