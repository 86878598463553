// @file
// Styles for the Flag module.

.flag {
  &.u-align-right {
    margin-bottom: 1em;
    text-align: right;

    .use-ajax {
      display: block;
    }

    .js-flag-message {
      display: block;
      margin-left: 0;
    }
  }
}
