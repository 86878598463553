// @file
// Styles for list.

@use '../../00-config' as *;

// .list {}

.list--border {
  @include list-border();
}

.list--clean {
  @include list-clean();
}

.list--column {
  @include list-column();
}

.list--inline {
  @include list-inline();
}

.list--pipeline {
  @include list-pipeline();
}
