// @file
// Styles for a 2 Column Grid Layout.

@use '../../../00-config' as *;

.l-grid--2-col {
  @include grid-gap(5);

  @include breakpoint(800px) {
    > * {
      @include grid-col(6);
    }
  }
}
