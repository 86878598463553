// @file
// Styles for Filters.

@use '../../00-config' as *;

.filters {
  margin-bottom: rem(gesso-spacing(3));
}

.filters__list {
  @include list-inline();
  display: inline-block;
  margin: 0 rem(gesso-spacing(2)) 0 0;
}

.filters__link {
  background-color: gesso-grayscale(gray-1);
  border-radius: radius('sm');
  color: gesso-grayscale(gray-5);
  display: inline-block;
  font-size: font-size(body, 2xs);
  line-height: gesso-line-height(2);
  margin: 0 rem(gesso-spacing('05')) rem(gesso-spacing('05')) 0;
  padding: rem(gesso-spacing('05')) rem(gesso-spacing(1));
  text-decoration: none;

  &:visited {
    color: gesso-grayscale(gray-5);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: gesso-grayscale(gray-2);
    color: gesso-grayscale(gray-6);
  }
}

.filters__remove {
  display: inline-block;
  font-size: font-size(body, 2xs);
  line-height: gesso-line-height(2);
  padding: rem(gesso-spacing('05')) rem(gesso-spacing(1));

  @media print {
    display: none;
  }
}
