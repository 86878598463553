// @file
// Iframe element styles.

iframe {
  border: 0;
  max-width: 100%;

  @media print {
    display: none;
  }
}
