// @file
// Accessibility utility classes

@use '../00-config' as *;

.element-invisible, // Drupal 7 class
.visually-hidden,
.u-visually-hidden,
.usa-sr-only {
  @include visually-hidden-important();

  &.element-focusable, // Drupal 7 class
  &.focusable,
  &.u-focusable {
    &:focus,
    &:active {
      @include visually-hidden-off-important();
    }
  }
}

.element-hidden, // Drupal 7 class
.hidden,
.u-hidden {
  @include hidden-important();
}

.invisible,
.u-invisible {
  @include invisible-important();
}
