// @file
// Styles for an Alert Box.

@use '../../../00-config' as *;
@use '../box' as *;

.box--alert {
  @extend %box--with-borders;

  > .box__title,
  > .box__footer {
    background-color: color($theme-color-error);
    border-color: color($theme-color-error);
    color: gesso-grayscale(white);
  }

  > .box__title > .box__title-link {
    color: gesso-grayscale(white);

    &::after {
      @include svg-background-important(external-link--white);
    }
  }

  > .box__image,
  > .box__image-link > .box__image {
    border-color: color($theme-color-error);
  }

  > .box__content {
    border-color: color($theme-color-error);
  }
}
