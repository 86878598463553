// @file
// Blockquote styles.

@use '../../../00-config' as *;

$blockquote-cite-divider-color: gesso-color(ui, generic, border);

%pull-quote {
  @include display-text-style(blockquote);
  border-left: rem(units(1)) solid gesso-brand(aqua, base);
  clear: both;
  margin: 0 0 rem(gesso-spacing(4));
  padding: rem(units($theme-alert-padding-x)) 0
    rem(units($theme-alert-padding-x)) rem(units($theme-alert-padding-x));

  > :last-child {
    margin-bottom: 0;
  }

  // Add top margin when preceded by another element.
  * + & {
    margin-top: rem(gesso-spacing(4));
  }
}

%pull-quote__cite {
  color: gesso-grayscale(gray-5);
  display: block;
  margin: rem(gesso-spacing(2)) 0;
  text-align: left;

  .usa-dark-background & {
    color: gesso-grayscale(gray-2);
  }
}

blockquote {
  @extend %pull-quote;

  @if $wysiwyg {
    font-style: normal;
  }

  p {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  cite {
    @extend %pull-quote__cite;

    em {
      border-left: 1px solid $blockquote-cite-divider-color;
      font-weight: font-weight(normal);
      margin-left: rem(gesso-spacing('05'));
      padding-left: rem(gesso-spacing(1));
    }
  }

  @media print {
    page-break-inside: avoid;
  }
}
