// @file
// Styles for progress bars.

@use '../../00-config/config.settings' as *;
@use '../../00-config/00-functions' as *;

$progress-background-color: gesso-color(ui, generic, background) !default;
$progress-bar-color: gesso-color(ui, generic, accent) !default;
$progress-border-color: gesso-color(ui, generic, border-dark) !default;
$progress-border-radius: rem(3px) !default;
$progress-text-color: gesso-color(text, on-light) !default;

.progress {
  position: relative;
}

.progress__track {
  background-color: $progress-background-color;
  border: 1px solid $progress-border-color;
  border-radius: $progress-border-radius;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  height: 1rem;
  margin: rem(gesso-spacing('05')) 0;
  max-width: 100%;
  min-width: 6em;
  overflow: hidden;
}

.progress__bar {
  background-color: $progress-bar-color;
  border-radius: $progress-border-radius;
  height: 1rem;
  transition-duration: 0.5s;
  transition-property: width;
  transition-timing-function: ease-out;
  width: 3%;
}

.progress__description,
.progress__percentage {
  color: $progress-text-color;
  font-size: font-size(body, 3xs);
  overflow: hidden;
}

.progress__description {
  float: left; // LTR

  @if $support-for-rtl {
    [dir='rtl'] & {
      float: right;
    }
  }
}

.progress__percentage {
  float: right; // LTR

  @if $support-for-rtl {
    [dir='rtl'] & {
      float: left;
    }
  }
}
