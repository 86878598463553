// @file
// Styles for radio form item.

@use '../../../00-config' as *;
@use 'usa-radio' as *;

.form-item--radio {
  margin: rem(gesso-spacing(1)) 0;

  .form-item__label {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .usa-radio__label;
  }
}
