// @file
// Styles for a Dark Button.

@use '../../../00-config' as *;

.button--dark {
  background-color: gesso-color(button, dark, background);
  color: gesso-color(button, dark, text);

  &:hover,
  &:focus {
    background-color: gesso-color(button, dark, background-hover);
    color: gesso-color(button, dark, text-hover);
  }

  &:active {
    background-color: gesso-color(button, dark, background-active);
    color: gesso-color(button, dark, text-active);
  }
}
