// @file
// Styles for the Back to Top.

@use '../../00-config' as *;

.back-to-top {
  display: none;

  @include breakpoint(gesso-breakpoint(mobile-lg)) {
    align-items: center;
    background-color: gesso-color(button, back-to-top, background);
    border-radius: 50%;
    bottom: rem(gesso-spacing(205));
    color: gesso-color(button, back-to-top, color);
    display: flex;
    height: rem(70px);
    justify-content: center;
    position: fixed;
    right: rem(gesso-spacing(205));
    transition-duration: gesso-duration(short);
    transition-property: background-color, color, opacity;
    transition-timing-function: gesso-easing(ease-in);
    width: rem(70px);

    &:visited {
      color: gesso-color(button, back-to-top, color);
    }

    &:hover,
    &:focus {
      background-color: gesso-color(button, back-to-top, background-hover);
      color: gesso-color(button, back-to-top, color-hover);
    }

    &[aria-hidden='true'] {
      opacity: 0;
    }

    // stylelint-disable-next-line selector-max-id
    #components-back-to-top & {
      opacity: 1;
    }
  }

  @media print {
    display: none;
  }
}

.back-to-top__icon {
  height: rem(22px);
  width: rem(35px);
}
