// @file
// Styles for New content.

@use '../../00-config' as *;
@use 'usa-tag' as *;
@use '../tag/tag--accent/tag--accent' as *;

$languages: (
  'es': 'Nuevo',
  'ar': 'جديد',
  'zh_CN': '新',
  'zh_TW': '新',
  'de': 'Neu',
  'bn': 'নতুন',
  'gu': 'નવું',
  'fr': 'Nouveau',
  'ht': 'Nouvo',
  'it': 'Nuovo',
  'ko': '신규',
  'pt': 'Novo',
  'ru': 'Новый',
  'tl': 'Bago',
  'vi': 'Mới',
);

.new::before {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .usa-tag;
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .usa-tag--accent;
  content: 'New';
  display: inline-block;
  margin-right: rem(units('05')) !important;

  @each $code, $text in $languages {
    article[lang='#{$code}'] & {
      content: $text;
    }
  }
}
