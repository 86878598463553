@use '../../00-config' as *;

.usa-alert {
  margin-bottom: rem(gesso-spacing(2));
  overflow: auto;

  .usa-alert__body {
    max-width: 100%;
  }
}
