// @file
// Component: Gallery Figure

@use '../../../00-config' as *;

.figure--gallery {
  margin: 0;
  max-width: rem(960px);

  .figure__caption {
    > :last-child {
      margin-bottom: 0;
    }
  }
}
