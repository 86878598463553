// @file
// Component: Video Figure

.figure--video {
  display: block;
  width: 100%;

  .figure__caption {
    display: block;
  }
}
