// @file
// Ordered list styles.

@use '../../../00-config' as *;

ol {
  @extend %usa-list;
  margin-top: 0;

  @if $support-for-rtl {
    [dir='rtl'] & {
      padding-left: 0;
      padding-right: 3ch;
    }
  }

  &:last-child {
    margin-bottom: revert;
  }

  ol,
  ul {
    margin-top: rem(gesso-spacing(1));
  }

  ol {
    list-style-type: lower-alpha;

    ol {
      list-style-type: lower-roman;
    }
  }
}
