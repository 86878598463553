// @file
// Component: Thumbnail Figure

@use '../../../00-config' as *;

.figure--thumbnail {
  max-width: rem(160px);

  @include breakpoint(gesso-breakpoint(tablet)) {
    &.u-align-left,
    &.u-align-right {
      max-width: Min(
        rem(160px),
        calc(40% - #{rem(gesso-get-map(gutter-width))})
      );
    }
  }
}
