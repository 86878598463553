// @file
// Component: Original Figure

@use '../../../00-config' as *;

.figure--original {
  @include breakpoint(gesso-breakpoint(tablet)) {
    &.u-align-left,
    &.u-align-right {
      max-width: calc(75% - #{rem(gesso-get-map(gutter-width))});
    }
  }
}
