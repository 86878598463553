// @file
// Styles for the sidenav menu.

@use '../../../00-config' as *;
@use 'usa-sidenav' as *;

.menu--sidenav {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .usa-sidenav;
  border-top: 2px solid gesso-grayscale(gray-6);

  .menu__item {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .usa-sidenav__item;

    &.has-subnav {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &.usa-accordion + .menu__item.usa-accordion {
      margin-top: 0;
    }

    &:first-child {
      border-top: 0;
    }
  }

  .menu__link {
    background-color: transparent;
    background-image: none;
    color: gesso-color(text, link) !important;
    font-weight: normal;

    &:hover,
    &:focus {
      color: gesso-color(text, link-hover) !important;
      position: relative;
      z-index: 1;
    }

    &:active {
      color: gesso-color(text, link-active) !important;
    }

    &:visited {
      color: gesso-color(text, link-visited) !important;
    }

    &.is-active,
    &.is-active-trail {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .usa-current;
      background-color: color('base-lightest');
      font-weight: bold;
    }

    &.has-subnav {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-left: gesso-spacing(2);
      padding-right: gesso-spacing(2);
      width: calc(100% - 62px);

      &[aria-expanded='true'] > .menu__link-icon {
        transform: rotate(0deg);
      }
    }

    &.usa-accordion__button {
      padding: gesso-spacing(1) gesso-spacing(3);
      width: rem(62px);

      &.is-active::after,
      &.is-active-trail::after {
        content: none;
      }
    }
  }

  .menu__link-icon {
    color: gesso-grayscale(black);
    font-size: 0.8em;
    top: -1px;
    transform: rotate(-180deg);
    transition: transform gesso-duration(standard) gesso-easing(ease-out);
  }

  .menu__subnav {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .usa-sidenav__sublist;
    align-items: center;
    width: 100%;

    .menu__subnav {
      font-size: rem(14px);
    }
  }
}

.menu--sidenav-nav {
  @include at-media-max($theme-header-min-width) {
    background: color('white');
    border-right: 0;
    display: none;
    flex-direction: column;
    height: auto;
    left: 0;
    margin-bottom: 0;
    max-height: 50vh;
    overflow-x: scroll;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    right: 0;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
    width: 100%;
    z-index: 1;

    &.is-visible {
      display: flex;
      margin-bottom: 0;
      transform: translateY(0);
    }
  }
}

.menu-sidenav__overlay {
  @include u-pin('all');
  background: color('black-transparent-70');
  opacity: opacity(0);
  position: fixed;
  transition: opacity $project-easing;
  visibility: hidden;
  z-index: 1;

  &.is-visible {
    opacity: opacity(100);
    visibility: visible;
  }
}

.menu-sidenav__contact {
  margin: gesso-spacing(4) 0;

  @include at-media-max($theme-header-min-width) {
    display: none;
  }

  // stylelint-disable-next-line selector-class-pattern
  &.-mobile {
    display: none;
    margin: 1rem 0;
    padding: 0 1rem;

    @include at-media-max($theme-header-min-width) {
      display: block;
    }
  }
}

.menu-sidenav--active {
  overflow: hidden;

  .l-header {
    z-index: 1;
  }
}
