// @file
// Table styles.

@use '../../../00-config' as *;

table {
  @include usa-table();
}

thead {
  @media print {
    display: table-header-group;
  }
}

tbody {
  th {
    background-color: color('base-lightest');
    font-weight: $theme-font-weight-bold;
  }
}

tr {
  @media print {
    page-break-inside: avoid;
  }
}
