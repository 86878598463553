// @file
// Styles for a striped table.

@use '../../../00-config' as *;

.usa-table--striped {
  tbody {
    th {
      background-color: transparent;
    }
  }

  .usa-dark-background & {
    // stylelint-disable-next-line selector-max-compound-selectors
    tbody {
      // stylelint-disable-next-line selector-max-compound-selectors
      th {
        background-color: gesso-grayscale(white);
      }
    }
  }
}
