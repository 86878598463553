// @file
// Styles for GovDelivery email sign-up.

@use '../../00-config' as *;
@use '../../02-base' as *;

.govdelivery {
  margin-bottom: rem(gesso-spacing(3));

  @media print {
    display: none;
  }
}

.govdelivery__legend {
  @extend %h3;
  margin-bottom: rem(gesso-spacing(1)) * -1;
}
