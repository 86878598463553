// @file
// Styles for a Reversed Hero.

@use '../../../00-config' as *;

.usa-hero--reversed {
  // Using ems here to match the breakpoint units in USWDS.
  @include breakpoint(em(gesso-breakpoint(tablet))) {
    > .grid-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
