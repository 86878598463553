// @file
// Horizontal rule styles.

@use '../../../00-config' as *;

hr {
  border-top: solid gesso-grayscale(gray-3);
  border-width: 1px 0 0;
  box-sizing: content-box;
  height: 0;
  margin: 0 0 rem(gesso-spacing(3));
  overflow: auto;

  // Add top margin when preceded by another element.
  * + & {
    margin-top: rem(gesso-spacing(3));
  }
}
