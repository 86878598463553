// @file
// Layout mixins

@use '../00-functions' as *;

@mixin layout-align-left($margin) {
  float: left;

  @if $margin {
    margin-right: $margin;
  }
}

@mixin layout-align-right($margin) {
  float: right;

  @if $margin {
    margin-left: $margin;
  }
}

@mixin layout-align-none {
  float: none;
  margin-left: 0;
  margin-right: 0;
}

@mixin layout-constrain(
  $max-width: gesso-constrain(md),
  $margins-mobile: gesso-site-margins(mobile),
  $margins: gesso-site-margins(desktop)
) {
  margin-left: auto;
  margin-right: auto;
  max-width: rem($max-width);

  @if $margins-mobile {
    padding-left: rem($margins-mobile);
    padding-right: rem($margins-mobile);
  }

  @if $margins {
    @include at-media($theme-site-margins-breakpoint) {
      padding-left: rem($margins);
      padding-right: rem($margins);
    }
  }

  // Wide template is enabled.
  // stylelint-disable-next-line selector-no-qualifying-type
  body.has-wide-template & {
    max-width: 100%;
  }
}
