// @file
// Styles for the Reversed Sidebar First Layout.

@use '../../../00-config' as *;

.l-sidebar-first--reversed {
  @include breakpoint(gesso-breakpoint(sidebar)) {
    flex-direction: row;

    .l-sidebar-first__sidebar {
      margin-left: 0;
      margin-right: rem(gesso-get-map(gutter-width));
    }
  }
}
