// @file
// Display text styles

@use '../00-functions' as *;

@mixin display-text-style($keys...) {
  $display: gesso-get-map(typography, display, $keys...);

  @each $property, $value in $display {
    @if ($property == 'font-size') {
      // Check for px if not output value.
      #{$property}: #{rem(convert($value))};
    } @else {
      #{$property}: #{$value};
    }
  }
}
