// @file
// Universal styles.

@use '../../../00-config' as *;

* {
  transition-duration: gesso-duration(short);
  transition-property: background-color, border-color, box-shadow, color,
    opacity, text-shadow, transform;
  transition-timing-function: gesso-easing(ease-in);
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    transition-duration: 0.01ms !important;
  }
}

@media print {
  * {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  @page {
    margin: 2cm;
  }
}

[id] {
  scroll-margin-top: 1em;
}
