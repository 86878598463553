// @file
// Component: Large Figure

@use '../../../00-config' as *;

.figure--large {
  max-width: rem(960px);

  @include breakpoint(gesso-breakpoint(tablet)) {
    &.u-align-left,
    &.u-align-right {
      max-width: calc(70% - #{rem(gesso-get-map(gutter-width))});
    }
  }
}
